import { useState } from "react";
import {
  LineChart, Line, BarChart, Bar, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from "recharts";

const ChartComponent = ({ rawData }) => {
  // Handle multiple datasets
  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState(0);
  const [chartType, setChartType] = useState("line");
  const [xAxisKey, setXAxisKey] = useState(""); 
  const [yAxisKey, setYAxisKey] = useState("");

  // Ensure rawData is valid
  const datasets = Array.isArray(rawData) ? rawData : [];
  const selectedDataset = datasets[selectedDatasetIndex] || [];
  const availableKeys = selectedDataset.length > 0 ? Object.keys(selectedDataset[0]) : [];

  // Default X and Y selections
  if (!xAxisKey && availableKeys.length > 0) setXAxisKey(availableKeys[0]);
  if (!yAxisKey && availableKeys.length > 1) setYAxisKey(availableKeys[1]);

  let formattedDataset;
  if (chartType === "pie") {
    console.log("Selected dataset:", selectedDataset);
    console.log("Data values:", selectedDataset.map(d => d[yAxisKey]));
    formattedDataset = selectedDataset
    .filter(item => item[yAxisKey] && !isNaN(item[yAxisKey])) // Remove invalid numeric entries
    .map(item => ({
      [xAxisKey]: item[xAxisKey] || "Unknown", // Use dynamic category column
      [yAxisKey]: Number(item[yAxisKey]) // Convert dynamic numeric column to number
    }));


  }
  

  return (
    <div className="max-h-[calc(100vh/3)] overflow-auto p-4 border rounded-md shadow">
      <h3 className="text-lg font-bold mb-4">Interactive Chart</h3>
      <div className="flex items-center">
      {/* Dataset Selector */}
      <div className="mb-4 mx-2">
        <label className="mr-2 font-semibold">Select Dataset:</label>
        <select 
          className="border p-2 rounded-md" 
          value={selectedDatasetIndex} 
          onChange={(e) => setSelectedDatasetIndex(Number(e.target.value))}
        >
          {datasets.map((_, index) => (
            <option key={index} value={index}>Dataset {index + 1}</option>
          ))}
        </select>
      </div>

      {/* Chart Type Selector */}
      <div className="mb-4 mx-2">
        <label className="mr-2 font-semibold">Select Chart Type:</label>
        <select 
          className="border p-2 rounded-md" 
          value={chartType} 
          onChange={(e) => setChartType(e.target.value)}
        >
          <option value="line">Line Chart</option>
          <option value="bar">Bar Chart</option>
          <option value="pie">Pie Chart</option>
        </select>
      </div>

      {/* X-Axis Selector */}
      <div className="mb-4 mx-2">
        <label className="mr-2 font-semibold">Select X-Axis:</label>
        <select 
          className="border p-2 rounded-md" 
          value={xAxisKey} 
          onChange={(e) => setXAxisKey(e.target.value)}
        >
          {availableKeys.map((key) => (
            <option key={key} value={key}>{key}</option>
          ))}
        </select>
      </div>

      {/* Y-Axis Selector (Not needed for Pie Chart) */}
      {chartType !== "pie" && (
        <div className="mb-4 mx-2">
          <label className="mr-2 font-semibold">Select Y-Axis:</label>
          <select 
            className="border p-2 rounded-md" 
            value={yAxisKey} 
            onChange={(e) => setYAxisKey(e.target.value)}
          >
            {availableKeys.map((key) => (
              <option key={key} value={key}>{key}</option>
            ))}
          </select>
        </div>
      )}
     </div>
      {/* Render the selected chart */}
      <ResponsiveContainer width="100%" height={300}>
        {chartType === "line" && (
          <LineChart data={selectedDataset}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisKey} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={yAxisKey} stroke="#F58F29" strokeWidth={2} />
          </LineChart>
        )}

        {chartType === "bar" && (
          <BarChart data={selectedDataset}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisKey} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={yAxisKey} fill="#F58F29" />
          </BarChart>
        )}

      
      {chartType === "pie" && formattedDataset.length > 0 && (
        <PieChart width={300} height={300}>
          <Tooltip />
          <Legend />
          <Pie
            data={formattedDataset}
            dataKey={yAxisKey} // Dynamically using the numeric column
            nameKey={xAxisKey} // Dynamically using the category column
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#F58F29"
            label
          />
        </PieChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default ChartComponent;
