import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import useFetchAssistants from '../hooks/useFetchAssistants';
import useFetchDataSources from '../hooks/useFetchDataSources';
import useFetchAnswers from '../hooks/useFetchAnswers';
import CustomBotMessage from "./CustomBotMessage";
import AssistantDropdown from "./AssistantsDropdown"; // Import your component


import botIcon from "../assets/qquest_logo.png";
import settingIcon from '../assets/setting.svg';
import sendIcon from '../assets/send.svg';
import userIcon from '../assets/user.png'

const ChatView = () => {
  const { assist_ID } = useParams();
// const assist_ID = "asst_Jr2vHLLNLKV6TRyqkM9bilsr";
  const { user } = useAuth();
  const navigate = useNavigate();
  const [assistants, setAssistants] = useState([]);
  const [currentAssist,setCurrentAssistant] = useState([]);
//   const [currentDS, setCurrentDS] = useState({});
  const [datasources, setDataSource] = useState([]);


  const [messageId,setMessageId] = useState('');
  const [SQLCode,setSQLCode] = useState([]);

  const [rawData,setRawData] = useState([]);


  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [replyMessage, setReplyMessage] = useState('')
  const fetchAnswers = useFetchAnswers(user, navigate);

//   console.log("debug - assistID:",assist_ID)
  useFetchAssistants(user, navigate, setAssistants);
  useFetchAssistants(user, navigate, setCurrentAssistant,assist_ID);
  console.log("debug - current assist:",currentAssist)
  const source_id = currentAssist.map(item => item.source_id);
//   console.log("debug:", source_id)
  console.log("the data source is:",source_id[0])
  useFetchDataSources(user, navigate, setDataSource,source_id[0]);
  console.log("current data source:", datasources)


  const handleMessageChange = (e) => {
    setInputMessage(e.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) { // Check if Enter is pressed without the Shift key
      event.preventDefault(); // Prevent the default action to stop from entering a new line
      handleSubmit(event); // Trigger the form submit
    }
  };

  const handleSelect = (selectedId) => {
    console.log("selectedId:",selectedId)
    const selectedAssistant = assistants.find(a => a.AssistantID === selectedId);
    // setCurrentAssistant(selectedAssistant); // Update state
    // navigate(`/dashboard/ChatView/${selectedId}`);
    if (selectedId) {
      window.location.href = `/dashboard/ChatView/${selectedAssistant.AssistantID}`; // Reloads the page with new URL
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputMessage.trim()) {
      const newMessage = inputMessage;
      setMessages(messages => [...messages, { text: newMessage, sender: 'user' }]);
      fetchResponse(newMessage);
      setInputMessage(''); // Clear input after sending
    }
  };




  const fetchResponse = async (userMessage) => {
    // Attempt to fetch a reply from the server/API
    let analyzingMessage;
    try {
        analyzingMessage = { text: "Analyzing...", sender: 'bot', id: Date.now() };
        setMessages(messages => [...messages, analyzingMessage]);

        // Define a callback to handle the message update
        const handleReplyMessage = (replyMessage, rawData, sqlCode, messageId) => {
            setMessages(messages => 
                messages.map(msg => 
                    msg.id === analyzingMessage.id 
                        ? { 
                            ...msg, 
                            text: replyMessage, 
                            rawData: rawData || [], 
                            SQLCode: sqlCode || [], 
                            id: messageId || null
                        } 
                        : msg
                )
            );
            console.log("Updated bot response:", { replyMessage, rawData, sqlCode, messageId });
        };        

        // Directly call the async function and wait for its response
         await fetchAnswers(user, navigate, datasources[0].name, userMessage, handleReplyMessage,  assist_ID, datasources[0].source_id);
        // const botMessage = replyMessage.content || `Echo: ${userMessage}`; // Fallback to an echo if no response
        // Update the messages state to include the new user and bot messages
    } catch (error) {
        console.error("Fetching error:", error);
        // Provide a fallback message or error message directly in the chat
        setMessages(messages => 
            messages.map(msg => 
                msg.id === analyzingMessage.id ? { ...msg, text: "Failed to fetch response." } : msg
            )
        );
    }
};


  return (
    <div className="flex flex-col h-screen">
    <div className="flex  items-center w-full p-4 ">
      <h1 className="font-bold m-2">Chat with your assistant:</h1>
      {/* <h1 className="font-bold">Chat with your assistant: {currentAssist[0]?.AssistantName || 'Your Assistant'}</h1> */}
    {currentAssist.length > 0 && (   <AssistantDropdown 
          currentAssist={currentAssist} 
          Assistants={assistants} 
          onSelect={handleSelect} 
        /> ) }
    </div>
  
    {/* Conditionally render the messages container and form placement */}
    {messages.length > 0 ? (
      <div className="flex flex-col flex-grow">
        <div className="overflow-y-auto p-2 border border-gray-300 rounded m-2 flex-grow">
        {messages.map((msg, index) =>
            msg.sender === "bot" ? (
                <div key={index} className="flex justify-start items-start mb-2">
                    <img src={botIcon} alt="Bot" className="w-5 h-5 mr-2 mt-2 rounded-full" /> {/* Bot profile image */}
                    {/* <div className="bg-white text-black rounded p-2.5 max-w-7/10 text-sm text-left font-normal leading-normal no-underline shadow whitespace-pre-wrap break-words" >{msg.text}</div> */}
                    <CustomBotMessage user = {user} message={msg.text} rawData={msg.rawData} SQL={msg.SQLCode} id = {msg.id }/>
                </div>
            ) : (
                <div key={index} className="flex justify-end items-center mb-2">
                    <div className="bg-white text-black rounded p-2.5 max-w-7/10 text-sm text-left font-normal leading-normal no-underline shadow whitespace-pre-wrap break-words" >{msg.text}</div>
                    <img src={userIcon} alt="User" className="w-5 h-5 ml-2 rounded-full" /> {/* User profile image */}
                </div>
            )
            )}
        </div>
        <div className="p-3">
  
        <form onSubmit={handleSubmit} className="flex items-end space-x-2">
            <textarea
                value={inputMessage}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
                className="flex-grow p-2 rounded resize-none"
                placeholder="Type your message here..."
                rows="3"
            />
            <button type="submit" className="flex items-center bg-white border border-gray-200 px-4 py-1 rounded-md shadow text-sm">
                <img src={sendIcon} alt="" className="w-auto h-auto mr-2"/>
                <span>Submit</span>
            </button>
        </form>

        </div>
      </div>
    ) : (
      <div className="flex-grow flex flex-col items-center justify-center p-4">
        <p className='text-gray-800 text-xl m-4'>What can I help with?</p>
        <form onSubmit={handleSubmit} className="w-full max-w-md">
          <textarea
            value={inputMessage}
            onChange={handleMessageChange}
            onKeyDown={handleKeyDown}
            className="w-full p-2 rounded resize-none"
            placeholder="Type your message here..."
            rows="3"
          />
          <button type="submit" className="flex items-center bg-white border border-gray-200 px-4 py-1 mt-1 rounded-md shadow w-50 ml-auto text-sm">
            <img src={sendIcon} alt="" className="w-auto h-auto mr-2"/>
            <span>Submit</span>
          </button>
        </form>
      </div>
    )}
  </div>
  
  
  
  );
};

export default ChatView;
