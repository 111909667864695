import React, {useState} from "react";
import button_plus from "../assets/button_white.svg";
import button_edit from "../assets/edit.svg";
import button_chat from "../assets/chat.svg";
import Modal from "./CreateAssistantsModal"
import { useAuth } from '../hooks/useAuth';
import useFetchAssistants from '../hooks/useFetchAssistants';
import { useNavigate } from 'react-router-dom';
import button_icon from "../assets/IconButton.svg";
import ActionMenu from "./ActionMenu";
import {format} from 'date-fns';
import { Link } from 'react-router-dom';


const Assistants = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { user} = useAuth();
  const [assistants, setAssistants] = useState([]);
  const navigate = useNavigate();

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleAssistantClick = (id) => {
    navigate(`/dashboard/Assistants/${id}`);
  };

  useFetchAssistants(user, navigate, setAssistants);
  // console.log(assistants)

    return (
        <>
        <div className="flex justify-between items-center w-full mb-3">
          <h1>Manage Assistants</h1>
          <button className="flex items-center justify-center bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-[200px]" onClick={openModal}>
            <img src={button_plus} alt='Create assistant' className='w-5 h-5 mr-2'/>
            <p className="text-white">Create assistant</p>
        </button>
        <Modal isOpen={isModalOpen} onClose={closeModal} user = {user}>
              
        </Modal>
        </div>
        {/* <div className="metrics flex justify-between items-center w-full mb-5">
          <div style={{ width: '30%' }} className="m1 flex items-center bg-white border border-white p-1 mt-1 mb-1 rounded-md shadow h-20">
          </div>
          <div style={{ width: '30%' }} className="m1 flex items-center bg-white border border-white p-1 mt-1 mb-1 rounded-md shadow h-20">
          </div>
          <div style={{ width: '30%' }} className="m1 flex items-center bg-white border border-white p-1 mt-1 mb-1 rounded-md shadow h-20">
          </div>
        </div> */}
        <div>
          <p>All assistants</p>
          <div className="overflow-x-auto mt-1 mb-1 rounded-md shadow">
            <table className="min-w-full divide-y divide-gray-200  ">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Assistant Name
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Data Source Name
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Created At
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Role
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            
                        </th>
                        {/* Add other headers as needed */}
                    </tr>
                </thead>
              
                <tbody className="bg-white divide-y divide-gray-200">
                  
                  {Array.isArray(assistants) && assistants.map((item, index) => (
                        <tr key={index}>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{item.AssistantName}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                            <Link to={`../../dashboard/DataSource/${item.source_id}`} className="hover:text-blue-500">
                              {item.data_source}
                            </Link>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{format(new Date(item.Created_at), 'PPPpp')}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{item.Role}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                {item.Role === "Owner" || item.Role === "Admin" ? 
                                  <button className="flex items-center bg-white] border border-gray-200 p-1 mt-1 mb-1 mr-1 rounded-md shadow w-50" onClick={()=>handleAssistantClick(item.AssistantID)}>
                                  <img src={button_edit} alt='Edit Assistants' className='w-5 h-5 mr-1 ml-1'/>
                                  <p className="text-black mr-1">Edit</p>
                              </button>
                                  : <p></p>}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                {item.Role === "Owner" || item.Role === "Admin" ? 
                                  <button className="flex items-center bg-white] border border-gray-200 p-1 mt-1 mb-1 mr-1 rounded-md shadow w-50" onClick={() => {
                                    navigate(`/dashboard/ChatView/${item.AssistantID}`);
                                    window.location.reload();
                                  }} >
                                  <img src={button_chat} alt='Edit Assistants' className='w-5 h-5 mr-1 ml-1'/>
                                  <p className="text-black mr-1">Chat</p>
                              </button>
                                  : <p></p>}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                              {item.Role === "Owner" ?
                                <ActionMenu 
                                buttonIcon={button_icon} 
                                itemId={item.AssistantID}
                                page ="Assistants - Owner"
                                user = {user}
                                itemName = {item.AssistantName}/>
                                : item.Role === "Admin" ? 
                                <ActionMenu 
                                buttonIcon={button_icon} 
                                itemId={item.AssistantID}
                                page ="Assistants - Admin"
                                user = {user}
                                itemName = {item.AssistantName}/> : <p></p>
                              }
                              
                            </td>
                           
                            {/* Render other fields as needed */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
          
        </div>
      </>
    )
}

export default Assistants