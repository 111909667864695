import React from 'react';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth'; // Import AuthProvider from its file location

import Pricing from "./layout/Pricing";
import Login from "./layout/Login";
import Register from "./layout/Register";
import Success from "./layout/Success";
import Cancel from "./layout/Cancel";
import ForgetPassword from './components/ForgetPassword'; // Adjust the import path as necessary
import  Unsubscribe from './layout/EmailUnsubscribe';

import Dashboard from './components/Dashboard';
import Profile from './components/Profile';
import Assistants from './components/Assistants';
import DataSource from './components/DataSource';
import EditAssistants from './components/EditAssistants';
import DataModel from './components/DataModel'
import DashboardDefault from './components/DashboardDefault';
import ChatView from './components/ChatView';


function App() {
  return (
   <>
    <BrowserRouter>
    <AuthProvider> {/* Include AuthProvider to wrap around your routes */}
      <Routes>
    {/* Protected Routes */}

      <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<DashboardDefault />} />
          <Route path="/dashboard/profile" element={<Profile />} />
          <Route path="/dashboard/Assistants" element={<Assistants />} />
          <Route path="/dashboard/Assistants/:assist_ID" element={<EditAssistants />} />
          <Route path="/dashboard/DataSource" element={<DataSource />} />
          <Route path="/dashboard/DataSource/:source_id" element={<DataModel />} />
          <Route path="/dashboard/ChatView/:assist_ID" element={<ChatView/>} />
        </Route>

        <Route path="/pricing" element={<Pricing />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/register" element={<Register />}/>
        <Route path="/success" element={<Success />}/>
        <Route path="/cancel" element={<Cancel />}/>
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/unsubscribe/:uid" element={<Unsubscribe />} />
        <Route path="/" element={<Login />} /> {/* Default route if you want to redirect to login */}
      
      </Routes>

    </AuthProvider>
    </BrowserRouter>
   </>
  )
}

export default App