import React from "react";

const AssistantDropdown = ({ currentAssist, Assistants, onSelect }) => {
  return (
    <select 
      className="w-[200px] px-2 py-1 border rounded-md shadow-sm" 
      value={currentAssist?.AssistantID || ""} 
      onChange={(e) => onSelect(e.target.value)}
    >
      <option className="text-gray-400" value="">
        {currentAssist[0].AssistantName || "Select an Assistant"}
      </option>
      {Assistants.map((assist) => (
        <option key={assist.AssistantID} value={assist.AssistantID}>
          {assist.AssistantName}
        </option>
      ))}
    </select>
  );
};

export default AssistantDropdown;
