import React, { useState } from 'react';
import {marked} from 'marked';
// import MonacoEditor from '@monaco-editor/react';
import ChartComponent from "../components/ChartComponent"; // Adjust the path as needed
import thumb_up from '../assets/thumb_up.svg'
import thumb_down from '../assets/thumb_down.svg'

const CustomBotMessage = ({ user, message, rawData, SQL, id }) => {
  // State to track the currently active tab
  const [activeTab, setActiveTab] = useState('sql');
  const [feedback, setFeedback] = useState({});

//   console.log("debug - rawData:", rawData)
//   console.log("debug - SQL:", SQL)
    console.log("debug: id",id, typeof(id))


  // Function to handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getHtml = () => {
    return { __html: marked(message) };
  };

  const handleFeedback = (messageId, feedbackType) => {
    if (user && user.uid) {
        if (feedbackType === "up") {
            // ✅ If thumbs up, send feedback immediately
            fetch(`https://api.qquest.io/chat-feedback`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    message_id: messageId,
                    rating: false,  // Indicates a positive rating
                    rating_details: "User liked the response"
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log('Feedback submitted successfully:', data);
                setFeedback(prev => ({ ...prev, [messageId]: "up" })); // Update state
            })
            .catch(error => console.log('Error submitting feedback:', error));
    
        } else {
            // 👎 If thumbs down, ask for feedback via a popup
            const userFeedback = prompt("Tell us more about your experience:");
            
            if (userFeedback) {
                fetch(`https://api.qquest.io/chat-feedback`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        message_id: messageId,
                        rating: true,  // Indicates a negative rating
                        rating_details: userFeedback
                    })
                })
                .then(response => response.json())
                .then(data => {
                    console.log('Feedback submitted successfully:', data);
                    setFeedback(prev => ({ ...prev, [messageId]: "down" })); // Update state
                })
                .catch(error => console.log('Error submitting feedback:', error));
            }
        }
    }
 
};


  return (
    <div className="bg-white text-black rounded p-2.5 w-auto max-w-full md:max-w-7/10 text-sm text-left font-normal leading-normal no-underline shadow whitespace-pre-wrap break-words">
      {/* <div>{message}</div> First row: Message */}
      <div className="text-sm p-1 " style={{ maxHeight: '100%', overflowY: 'auto' }}>
                                <div
                                className="markdown"
                                dangerouslySetInnerHTML={getHtml()}
                                />
                            </div>
      {/* Conditional rendering for tabs if rawData or SQL is not empty */}
      {(Array.isArray(rawData) && Array.isArray(SQL) ) && (
        <div className="mt-2"> {/* Second row: Tabs */}
          <button onClick={() => handleTabChange('sql')} className={`border border-gray-200 px-2 py-1 m-1 rounded-md shadow text-[#1E293B] ${activeTab === 'sql' ? 'bg-bright-orange  text-white' : 'bg-white text-black'}`}>SQL Code</button>
          <button onClick={() => handleTabChange('data')} className={`  border border-gray-200 px-2 py-1 m-1 rounded-md shadow text-[#1E293B] ${activeTab === 'data' ? 'bg-[#F58F29] text-white' : 'bg-white text-black'}`}>Data</button>
          <button onClick={() => handleTabChange('charts')} className={` border border-gray-200 px-2 py-1 m-1 rounded-md shadow text-[#1E293B] ${activeTab === 'charts' ? 'bg-[#F58F29] text-white' : 'bg-white text-black'}`}>Charts</button>
        </div>
      )}

      {/* Render tab content only if tabs are visible */}
      {((Array.isArray(rawData) && rawData?.length > 0) || (Array.isArray(SQL) && SQL?.length > 0)) && (
        <div className="mt-2"> {/* Content area based on active tab */} 
        {activeTab === 'sql' && SQL && (
            <div className="bg-gray-900 text-white p-3 rounded-md overflow-auto">
                <pre className="whitespace-pre-wrap break-words">
                <code>{SQL} </code>
                </pre>
            </div>
            )}
        {activeTab === "data" && Array.isArray(rawData) && rawData.length > 0 && (
            <div className="max-h-[calc(100vh/3)] overflow-auto space-y-6 ">
                {rawData.map((dataset, datasetIndex) => (
                <div key={datasetIndex} className="border p-4 rounded-md shadow">
                    <h3 className="text-lg font-bold mb-2">Dataset {datasetIndex + 1}</h3>
                    {Array.isArray(dataset) && dataset.length > 0 ? (
                    <table className="min-w-full border-collapse border border-gray-300">
                        {/* Table Header */}
                        <thead>
                        <tr className="bg-gray-200">
                            {Object.keys(dataset[0]).map((key) => (
                            <th key={key} className="border border-gray-400 px-4 py-2 text-left">
                                {key}
                            </th>
                            ))}
                        </tr>
                        </thead>
                        {/* Table Body */}
                        <tbody>
                        {dataset.map((row, rowIndex) => (
                            <tr key={rowIndex} className="hover:bg-gray-100">
                            {Object.keys(row).map((key) => (
                                <td key={key} className="border border-gray-400 px-4 py-2">
                                {typeof row[key] === "object" ? JSON.stringify(row[key]) : row[key]}
                                </td>
                            ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    ) : (
                    <p className="text-gray-500">No data available</p>
                    )}
                </div>
                ))}
            </div>
            )}
        {activeTab === "charts" && Array.isArray(rawData) && rawData.length > 0 && (
            <ChartComponent rawData={rawData} />
            )}

        </div>
      )}

{(typeof id === 'string' && id.includes('msg_')) && (
    <div className="mt-3 flex items-center space-x-3"> {/* Flexbox for alignment */}
        <span className="text-gray-600 text-sm font-medium">Was this helpful?</span>
        
        <div className="flex space-x-1"> {/* Spacing between thumbs */}
            <button 
                id={`${id}-thumbUp`} 
                onClick={() => handleFeedback(id, "up")}
                className={`p-2 rounded-full border transition ${
                    feedback[id] === "up" ? "bg-gray-300 border-gray-500 text-white" : "border-gray-300 hover:bg-gray-100 hover:border-gray-500"
                }`}
                disabled={!!feedback[id]} // Disable after selection
                aria-label="Thumbs Up"
            >
                <img src={thumb_up} alt="Thumbs Up" className="w-6 h-6" />
            </button>

            <button 
                id={`${id}-thumbDown`} 
                onClick={() => handleFeedback(id, "down")}
                className={`p-2 rounded-full border transition ${
                    feedback[id] === "down" ? "bg-gray-300 border-gray-500 text-white" : "border-gray-300 hover:bg-gray-100 hover:border-gray-500"
                }`}
                disabled={!!feedback[id]} // Disable after selection
                aria-label="Thumbs Down"
            >
                <img src={thumb_down} alt="Thumbs Down" className="w-6 h-6" />
            </button>
        </div>
    </div>
)}


      
    </div>
  );
};

export default CustomBotMessage;
